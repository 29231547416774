import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn, FaTiktok, FaYoutube, } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
               <span className="purple"> ME </span> PRESENTO
            </h1>
            <p className="home-about-body">

              Mi nombre es <b className="purple"> José Alejandro</b>, aunque la mayoría me conoce por mi apellido: <b className="purple"> BORGES</b>, de allí viene mi pseudónimo.

              <br/>
              <br/>Amo programar, lo considero como un arte: el arte de crear, de innovar, la carrera del autodidacta eterno...
              <br />
              <br />Desde niño siempre me apasionó la tecnología y la innovación, es por eso que decidí convertirme en programador, para poder aportar mi granito de arena hacia el futuro. Las curiosidades tecnológicas y parte de mi conocimiento las comparto en mis redes sociales, principalmente en 
              <i>
                <a href="https://www.tiktok.com/@borgesit3.0?_t=8hqoQ5wiGhy&_r=1" target="_BLANK"><b className="purple"> TikTok</b></a>.
              </i> y 
              <i>
                <a href="https://www.youtube.com/@BorgesIT" target="_BLANK"><b className="purple"> Youtube</b></a>.
              </i>
              <br />
              <br />
              Estoy en constante aprendizaje, por lo que trabajar en equipo y poder aprender de mis compañeros me parece una virtud,
              <i>
                <b className="purple"> la unión hace la fuerza. </b> "Aunque estando sólo puedes ir rápido, estar acompañado te permitirá llegar más lejos".
              </i>
              <br />
              <br />
              Mi campo de interés está inclinado hacia el
              <i>
              <b className="purple"> Desarrollo Web</b>, la
                <b className="purple"> Tecnología Blockchain </b>
                , la creación de{" "}
                <b className="purple">
                  DApps interactivas 
                </b> y la <b className="purple">Inteligencia Artificial.</b>
              </i>
              <br />
              <br />
              Mi pasión por crear cosas nuevas, me llevó a estudiar herramientas como <b className="purple">HTML, CSS, JavaScript y Python</b> para poder combinarlas con sus respectivas
              <i>
                <b className="purple">
                  {" "}
                 Librerías y Frameworks
                </b>
              </i>
              &nbsp;, como
              <i>
                <b className="purple"> Bootstrap, ReactJS, FLASK y NodeJS</b>.
              </i><br/><br/>

              Seguiré aprendiendo e innovando en este hermoso Mundo de la <b className="purple">Tecnología</b>, si quieres conocer más sobre mi, hay más información acá mismo en mi sitio, y también te dejaré mis redes sociales. <b className="purple">¡Saludos!</b>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>ACÁ PUEDES CONSEGUIRME</h1>
            <p>
              Con toda confianza <span className="purple">contáctate </span>conmigo
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.tiktok.com/@borgesit3.0"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTiktok />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.youtube.com/@BorgesIT"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaYoutube />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/BorgesIT"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://twitter.com/BorgesIT"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/josea-borgesit/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/borgesit3.0?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
