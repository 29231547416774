import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import crud from "../../Assets/Projects/crud.png";
import portfolio from "../../Assets/Projects/portfolio.png";
import staking from "../../Assets/Projects/staking.png";
import cryptoPrice from "../../Assets/Projects/crypto-price.png";
import tpo from "../../Assets/Projects/tpo.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mi reciente <strong className="purple">Trabajo </strong>
        </h1>
        <p style={{ color: "white" }}>
          Aquí hay algunos proyectos en los que trabajé recientemente.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cryptoPrice}
              isBlog={false}
              title="Cotizador de Criptomonedas"
              description="Un cotizador de criptomonedas, construido con ReactJS, que toma las principales 100 criptomonedas según su capitalización de mercado, usando la API de CoinGecko, y las muestra de manera ordenada, además ofrece datos, como el precio, porcentaje de subida, volumen, entre otros, y una descripción de cada moneda en particular."
              ghLink="https://github.com/BorgesIT/cripto-price"
              demoLink="https://crypto-price.borgesit.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tpo}
              isBlog={false}
              title="Mi primer trabajo"
              description="Este es mi primer trabajo práctico, que realicé para el curso de Codo a Codo. Está hecho solo con HTML, CSS y JavaScript, y cumple con diseño responsivo hecho con Flex-box y grid, además tiene un formulario de registro que valida con JavaScript, abajo puedes encontrar la vista previa y el código fuente de GitHub."
              ghLink="https://github.com/BorgesIT/TPO-CAC"
              demoLink="https://primer-tpo.borgesit.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={staking}
              isBlog={false}
              title="DApp Staking de NFT"
              description="Diseño de una DApp para Staking de la colección NFT Bitk2Water del proyecto Bitk2Coin totalmente funcional; conectada con ReactJS y JavaScript, a SmartContracts de solidity, invocando las funciones por medio de las ABI en formato JSON y las respectivas dependencias de la web3."
              ghLink="https://github.com/BorgesIT/stakingNFT"
              // demoLink="https://bitk2water.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={crud}
              isBlog={false}
              title="CRUD con Python"
              description="CRUD creado con Python, Flask y MySQL, para descarga de ebooks, con login para administrador. También puede modificarse para crear tienda de artículos, con la misma integración en la base de datos."
              ghLink="https://github.com/BorgesIT/crudPython"
              // demoLink=""              
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
